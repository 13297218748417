<template>
  <div class="user">
    <all-log :dialogLog="dialogLog" />
    <!-- 拒绝提示 -->
    <el-dialog :visible.sync="dialogVisible" width="30%" :append-to-body="true">
      <div slot="title" class="dialog-footer">
        <div class="title">拒绝出库</div>
      </div>
      <el-input
        type="textarea"
        autosize
        placeholder="拒绝原因"
        maxlength="10"
        v-model="textarea1"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setDel">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 面包屑导航 -->
    <el-card>
      <div class="menu-box">
        <h2>出库审批管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="3">
              <el-button type="success" @click="multipleApproval"
                >批量通过申请</el-button
              >
            </el-col>
            <el-col :span="3">
              <el-popover
                v-model="visible"
                placement="right"
                width="100%"
                trigger="click"
              >
                <div class="report-popover-wrapper">
                  <el-form :model="getInfo">
                    <el-form-item label-width="90px" label="商品名称">
                      <el-input
                        size="mini"
                        v-model="getInfo.mp_name"
                        placeholder="商品名称"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label-width="90px" label="商品编号">
                      <el-input
                        size="mini"
                        v-model="getInfo.numbering"
                        placeholder="商品编号"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label-width="90px" label="店铺名称">
                      <el-select
                        v-model="getInfo.app_id"
                        size="mini"
                        style="width: 100%"
                        placeholder="请选择店铺"
                        clearable
                      >
                        <el-option
                          v-for="item in storeList"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label-width="90px" label="销售员">
                      <el-select
                        size="mini"
                        v-model="getInfo.seller_id"
                        style="width: 100%"
                        placeholder="请选择销售员"
                        clearable
                      >
                        <el-option
                          v-for="item in userList"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label-width="90px" label="出库单号">
                      <el-input
                        size="mini"
                        v-model="getInfo.outbound_no"
                        placeholder="出库单号"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label-width="90px" label="申请时间段">
                      <el-date-picker
                        size="mini"
                        v-model="getInfo.apply_time"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                      >
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item label-width="90px" label="审批状态">
                      <el-select
                        v-model="getInfo.status"
                        style="width: 100%"
                        size="mini"
                        placeholder="请选择审批状态"
                        clearable
                      >
                        <el-option
                          v-for="item in approvalStatus"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>

                  <div
                    class="footer"
                    style="display:flex;justify-content: end;"
                  >
                    <el-button size="mini" type="text" @click="visible = false"
                      >取消</el-button
                    >
                    <el-button type="primary" size="mini" @click="searchList()"
                      >确定</el-button
                    >
                  </div>
                </div>
                <el-button type="primary" slot="reference">高级搜索</el-button>
              </el-popover>

              <!-- <el-button type="primary" @click="showSearch">高级搜索</el-button> -->
            </el-col>
            <el-col :span="3">
              <el-button type="warning" @click="dialogLog.state = true"
                >查看日志</el-button
              >
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="loading"
            ref="multipleTable"
            class="approval-table"
            :data="tableData"
            @selection-change="handleChange"
            tooltip-effect="dark"
            style="width: 100%"
            :row-key="getRowKeys"
          >
            <el-table-column
              type="selection"
              :reserve-selection="true"
              :selectable="selectable"
              label-width="150"
            />
            <el-table-column width="160px" prop="mp_name" label="商品名称" />
            <el-table-column width="120px" prop="numbering" label="商品编号" />
            <el-table-column prop="type" width="100px" label="出库类型" />
            <el-table-column prop="spec_name" label="规格名" />
            <!-- <el-table-column prop="add_time" width="120px" label="申请时间" /> -->
            <el-table-column prop="seller_name" label="售卖员" />
            <el-table-column
              prop="outbound_no"
              label="出库单号"
              width="140px"
            ></el-table-column>
            <el-table-column
              prop="apply_time"
              width="120px"
              label="申请时间"
            ></el-table-column>
            <el-table-column prop="status" width="120px" label="审批状态">
              <template slot-scope="scope">
                <!-- <el-switch
              v-model="scope.row.state"
              :active-value="1"
              :inactive-value="2"
              @change="changeState(scope.row)"
               
            /> -->
                <el-tag v-if="scope.row.status === 2" type="danger"
                  >已拒绝</el-tag
                >
                <el-tag v-if="scope.row.status === 1" type="success"
                  >已通过</el-tag
                >

                <el-tag v-if="scope.row.status === 0" type="warning"
                  >待审核</el-tag
                >
              </template>
            </el-table-column>

            <el-table-column label="操作" width="300">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.status === 0"
                  size="mini"
                  type="success"
                  icon="el-icon-check"
                  @click="pass(scope.row)"
                  >通过</el-button
                >

                <el-button
                  v-if="scope.row.status === 0"
                  size="mini"
                  type="danger"
                  icon="el-icon-close"
                  @click="decline(scope.row)"
                  >拒绝</el-button
                >
                <el-button
                  size="mini"
                  icon="el-icon-search"
                  @click="openInfo(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @current-change="pageChange"
            :pager-count="15"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.getInfo.page_num"
            background
            :total="exp.num"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 详情-->
    <el-dialog
      title="出库单详情"
      :before-close="handleClose"
      :visible.sync="addDialogVisible"
      width="80%"
    >
      <el-descriptions>
        <el-descriptions-item label="出库类型">{{
          approvalInfo.type
        }}</el-descriptions-item>
        <el-descriptions-item label="出库时间">{{
          approvalInfo.apply_time
        }}</el-descriptions-item>
        <el-descriptions-item label="商品类型">{{
          approvalInfo.differentiate
        }}</el-descriptions-item>
        <el-descriptions-item label="店铺名称">{{
          approvalInfo.shop_name
        }}</el-descriptions-item>
        <el-descriptions-item
          v-if="approvalInfo.status === 2"
          label="拒绝理由"
          >{{ approvalInfo.content }}</el-descriptions-item
        >
        <el-descriptions-item label="出库单号">{{
          approvalInfo.outbound_no
        }}</el-descriptions-item>
        <el-descriptions-item label="审批状态">
          <el-tag size="mini" v-if="approvalInfo.status === 2" type="success"
            >已通过</el-tag
          >
          <el-tag size="mini" v-if="approvalInfo.status === 1">部分审批</el-tag>
          <el-tag size="mini" v-if="approvalInfo.status === 3" type="danger"
            >已拒绝</el-tag
          >
          <el-tag size="mini" v-if="approvalInfo.status === 0" type="warning"
            >待审核</el-tag
          >
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions-item label="销售员">{{
        approvalInfo.seller_name
      }}</el-descriptions-item>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAdd()">取 消</el-button>
        <el-button type="primary" @click="confirmAdd()">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getList,
  getUnify,
  getDel,
  outboundApprovalInfo,
} from '@/api/psi/inventory.js'
import { getStoreList, getUserlist } from '@/api/psi/storageAll.js'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      visible: false,
      approvalStatus: [
        {
          value: 0,
          name: '待审批',
        },
        {
          value: 1,
          name: '审批通过',
        },
        {
          value: 2,
          name: '审批拒绝',
        },
        // {
        //   value: 'all',
        //   name: '状态不限',
        // },
      ],
      loading: false,
      dialogLog: {
        state: false,
      },
      storeList: [],
      textarea1: '',
      spacer: '\u00a0\u00a0\u00a0',
      openState: true,
      approvalInfo: {},
      addDialogVisible: false,
      //配置
      optionProps: {
        checkStrictly: true,
        value: 'id',
        label: 'name',
        children: 'son',
      },
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: '',
        numbering: '',
        page_code: 1,
        page_num: 7,
        status: '',
        apply_time: '',
        app_id: '',
        seller_id: '',
        outbound_no: '',
        mp_name: '',
      },
      //导航
      nav: [],
      //单选列表
      radiolist: [
        {
          id: 1,
          name: '一级',
        },
        {
          id: 2,
          name: '二级',
        },
      ],
      //上级列表
      options: [],
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {
        w_id: '',
        area: '',
        seat: '',
      },
      checkedItem: [],
      //表单名称长度
      formLabelWidth: '120px',
      //控制弹窗删除
      dialogVisible: false,
      //控制弹窗--新增修改
      dialogFormVisible: false,
      //搜索框
      inputSearch: '',
      //表格数据
      tableData: [],
      ids: [],
      searchFields: {},
      warningText: '',
      userList: [],
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async getSelectList() {
      const res2 = await getStoreList()
      this.storeList = res2.data.data

      const res3 = await getUserlist()
      this.userList = res3.data.data
    },
    dialogOpen() {
      this.dialogLog.state = true
    },
    handleClose(row) {
      this.addDialogVisible = false
      this.approvalInfo = {}
    },
    // 打开详情
    async openInfo(row) {
      this.addDialogVisible = true
      const res = await outboundApprovalInfo({ approval_id: row.id })
      console.log(res)
      this.approvalInfo = res.data.data
    },
    handleChange(val) {
      console.log(val)
      this.checkedItem = val
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value)
      this.getInfo.page_num = value
      this.catalogue()
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page = value
      this.catalogue()
    },
    selectable(row) {
      //console.log(row)
      if (row.status === 0) {
        return true
      } else {
        return false
      }
    },
    //打开添加
    addOpen() {
      this.openState = true
      this.dialogFormVisible = true
    },
    getRowKeys(row) {
      //console.log(row.id);
      // this.rowKeyArr.push(row.id)
      return row.id
    },
    //同意打开
    async unify(row) {
      let info = await getUnify({ id: row.id })
      this.judge(info)
    },
    //删除打开
    delOpen(row, form) {
      this.dialogVisible = true
      this.userdelinfo = row
    },
    // 拒绝
    async setDel() {
      let info = await getDel({
        id: this.userdelinfo.id,
        content: this.textarea1,
      })
      this.judge(info)
    },
    //搜索事件
    search(e) {
      console.log('搜索信息: ' + e)
    },
    judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.dialogFormVisible = false
        this.dialogVisible = false
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
    },
    async decline(row) {
      this.$prompt('请输入拒绝原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async ({ value }) => {
          const res = await getDel({
            approval_id: row.id,
            content: value,
          })
          console.log(res)
          if (res.data.code === 1) {
            this.$message.success('已拒绝该出库申请!')
            this.$refs.multipleTable.clearSelection()

            this.catalogue()
          } else {
            this.$message.warning('拒绝出库申请失败!')
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消拒绝',
          })
        })
    },
    multipleApproval() {
      this.ids = []
      if (this.checkedItem.length === 0) {
        this.$message.warning('批量同意出库审批：请选择需要同意的审批申请！')
      } else {
        this.$confirm('确认批量通过出库申请?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info',
        })
          .then(async () => {
            this.checkedItem.forEach((item) => {
              this.ids.push(item.id)
            })
            const res = await getUnify({ ids: this.ids })
            if (res.data.code === 1) {
              this.$message.success('已批量通过出库申请!')
              this.$refs.multipleTable.clearSelection()

              this.catalogue()
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消通过',
            })
          })
      }
    },
    // 通过
    async pass(row) {
      this.$confirm('确认通过?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
      })
        .then(async () => {
          // const res = await getUnify({ ids: [row.id] })
          const res = await getUnify({ ids: [row.id] })
          if (res.data.code === 1) {
            this.$message.success('已通过该出库申请!')
            this.$refs.multipleTable.clearSelection()

            this.catalogue()
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消通过',
          })
        })
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        w_id: '',
        area: '',
        seat: '',
      }
      console.log('close')
    },
    //目录接口
    async catalogue() {
      this.loading = true
      let info = await getList(this.getInfo)
      this.tableData = info.data.data
      this.exp.count = info.data.exp.count
      this.exp.num = info.data.exp.num
      this.loading = false
    },
    searchList() {
      let time = this.getInfo.apply_time
      if (time.length === 2) {
        time = time[0] + '--' + time[1]
        this.getInfo.apply_time = time
      }
      this.catalogue()
      this.visible = false
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue()
    this.getSelectList()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
}
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand >>> .el-form-item__label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand >>> .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 30%;
}
.approval-table >>> .el-checkbox {
  zoom: 200% !important;
}
</style>
