import { post } from '@/utils/request'

//获取目录
export function getList(data) {
    return post('admin/inventory/outboundApprovalList', data)
}

//拒绝
export function getDel(data) {
    return post('admin/inventory/outboundApprovalRefuse', data)
}
//同意
export function getUnify(data) {
    return post('admin/inventory/outboundApprovalPass', data)
}
//详情
export function outboundApprovalInfo(data) {
    return post('admin/inventory/outboundApprovalInfo', data)
}





